 @import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");
.cryptoContainer{
  background-image: linear-gradient(to right, #e2eefb 50%, #F2F5F9);  
}

.container {
  width: 50%;
  height: 100%;
  margin: 0 auto;

}

.spinner{
  width: 50%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}


.crypto-list {
  list-style-type: none;
  padding: 0;
  margin-top: 50px;
}

.crypto-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
}
.crypto-item:hover{
  border: 1px solid #ffb711;
}

.crypto-item-symbol {
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.crypto-item-details {
  flex: 1;
  margin-left: 25px;
}

.crypto-item-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}


.crypto-item-price {
  font-size: 16px;
  margin-bottom: 5px;
}

.crypto-item-change {
  font-size: 14px;
  margin-bottom: 5px;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-around;
} 

.pagination button {

  padding: 5px 10px;
  height: 40px;
  width: 80px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.future {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-size: 70px;
  padding: 15px;
 color: #0879dc;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Lora", serif;
  text-transform: none;
  text-align: left;
}

.search-bar-holder {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search2 {
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-top: 25px;
  border: none;
  border-radius: 5px;
  padding: 0 16px;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search2:focus {
  outline: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.search2::placeholder {
  opacity: 0.8;
  color: #0879dc;
}
@media screen and (max-width: 768px) {
  .future {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 35px;
    padding: 15px;
    color: #0879dc;
    font-weight: 600;
   
    font-family: "Lora", serif;
    text-transform: none;
    
  }
}