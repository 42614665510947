* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel {
  display: flex;
  height: 510px;
  width: 40%;
}

.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel_cards {
  display: flex;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition: 0.5s ease-in-out;
}

.card_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel_cards-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}

@media only screen and (max-width: 768px) {
  .carousel {
    height: 50vh;
  }
}
