@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
* {
  margin: 0;
  padding: 0;
  
}


/*Fundbox*/

.fundboxContainer{
  background-image: linear-gradient(to right, #e2eefb 50%, #F2F5F9);

}
.card-div{
  height: 90vh;


}
.flex-div{
  margin: 10px;
  width: 80%;
  margin: auto auto auto auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;

}
.fund-stats{
  text-align: left;
  display: flex;
   justify-content: space-between;
}
.stats-value{
  color: #0879dc;
  font-size: 35px;
  font-family: 'Roboto Slab', serif;
   text-shadow: -2px 4px 8px rgba(4, 254, 252, 0.82); 
}

.stats-topics{
  font-size: 38px ;
  font-family: 'Roboto Slab', serif;  
  color: #004e92;
}




.fund-name{
  font-size: 45px ;
  font-family: 'Roboto Slab', serif;  
  color: #004e92;
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 65vh;
}
.stats-heading {
  font-size: 75px;
  font-family: 'Roboto Slab', serif;  
  color: #004e92;
  margin-top: 25px;
}
.priciple {
  font-size: 55px;
  font-family: 'Roboto Slab', serif;  
  color: #0879dc;
}
.over {
  font-size: 35px;
  width: 15vw;
  color: #0879dc;
}

.principleAmount{
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-top: 25px;
  border: none;
  border-radius: 5px;
  padding: 0 16px;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.principleAmount:focus{
  outline: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.button-box {
  display: flex;

  align-items: center;
  justify-content: space-evenly;
  height: 6vh;
  width: 34vw;

  font-size: 28px;
  font-family: 'Roboto Slab', serif;   
  text-align: center;

}

#button1, #button2{
  width: 5vw;
  color: #0879dc;

  border: none;
  height: 5vh;
  display: flex;
  justify-content: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px 20px 20px 20px;
}

.selection:hover {
  transition: 0.4s;
  letter-spacing: 2px;
  cursor: pointer;
  background-image: linear-gradient(to bottom right, #04fefc 30%, #004e92);
}
.selection:active {
  transform: translateY(3px);
}

.heading1 {
  font-size: 50px;
  text-shadow: -2px 4px 8px rgba(4, 254, 252, 0.82);
}
.returns {
  color: #0879dc;
  height: 60vh;
  width: 100%;
  margin-top: 80px;
  padding: 20px;
  font-family: 'Roboto Slab', serif;   
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.valueReturns{
  color: #f60000ea;
}
.return-box {
  display: flex;
  margin-top: 15px;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

/*FundBox End*/

/*SearchBar*/
.typewriter{
  color: #004e92;
  font-size: 2.4rem;
  font-family: 'Roboto Slab', serif;   
  font-weight: bolder;

}

.search-carousel{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  width: 100%;
 
  background-image: linear-gradient(to right, #e2eefb 50%, #F2F5F9);
}

.justSearch{
 
  height: 40vh;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.search-bar {
  height: 6vh;
  width: 60%;
  border-radius: 10px;
  color: red;
  text-align: center;
  font-size: 20px;
  outline: none;
  margin: auto;
  outline: none;
}

.search-bar:focus{
  outline: none;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.4);
 
}
.search-bar::placeholder{
  color: #2196f3;
  text-align: center;
  font-size: 20px;
  font-family: "Lora", serif;
}
.search-bar:focus::placeholder{
  color: transparent;
}
.search-results {
  position: absolute;
  right: 51%;
  top: 53%;
  height: 30vh;
  z-index: 1;
  font-size: 1.1rem;
  font-weight: 800;
  overflow: hidden;
  overflow-y: auto;
  background-image: linear-gradient(to right, #e2eefb 50%, #F2F5F9);
}

.search-results p {
  
  width: 35vw;
  color: #2196f3;
  height: 5vh;
  font-family: "Lora", serif;
  padding: 18px;
}
.search-results p:hover {
  cursor: pointer;
  color: #ffb711;
  transition: 0.1s;
  border-bottom: 1px solid #ffb711;
}
.search-results p:active {
  transform: translateY(1px);
}
/*Responsiveness*/


@media screen and (max-width: 768px) {
  .search-carousel{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
   }
   .justSearch{
 
    height: 20vh;
  }
   .search-bar {
    height: 6vh;
    width: 90%;
     text-align: center;
    font-size: 13px;
    outline: none;
    margin: auto;
    outline: none;
  }
  .search-bar::placeholder{
    font-size: 13px;
  }
  .search-results {
    position: absolute;
    right: 32%;
    top: 70%;
    height: 35vh;
    font-size: 13px;
}
.search-results p{
  margin: 8px;
}
.typewriter{
  color: #004e92;
  font-size: 14px;
  font-family: 'Roboto Slab', serif;   
  font-weight: bolder;
  margin-bottom: 25px;

}

  
}