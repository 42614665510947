.abc{
    background-image: linear-gradient(to right, #e2eefb 50%, #F2F5F9);
   
}

.explorer-container{
    height: max-content;
    border-bottom: 1px solid #004e92;
}
.targets{
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right, #e2eefb 50%, #F2F5F9);
    border-top: 1px solid #004e92;

 
}
.but-container{
    height: 15vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.but-container2{
    height: 10vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.sub-buttons{
    background-color: #004e92;
    border-radius: 10px;
    width: 12vw;
    height: 55px;
    text-align: center;
    font-size: 1.1rem;
    color: #fff; 
    margin: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}
.explore-table{
  margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    justify-content: center;
}
.noactive{
    width: 50%;
    margin: 0 auto;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: #004e92;

}
.search3{
    margin-top: 20px;
}
/* For screens with a width of 768px or less */
@media (max-width: 768px) {
    .sub-buttons{

        width: 15vw;
        height: 55px;
        text-align: center;
        font-size: 10px;
        color: #fff; 
        margin: 3px;

    }



}
  
