
.footer {
  border-top: 1px solid #0879dc ;
  background-image: linear-gradient(to right, #e2eefb 50%, #F2F5F9);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;


}

.footer__social {
  display: flex;
  justify-content: center;
  padding: 3px;
}

.footer__social a {
  color: #0879dc;
  font-size: 2.5rem;
  margin: 0 1rem;
}

.footer__links {
  display: flex;
  justify-content: center;
  margin-bottom: 1.8rem;
 
  width: 30vw;
}

.footer__links a {
  color: #0879dc;
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1.5rem;
}

.footer__links a:hover {
  text-decoration: underline;
}
.footer__copyright {
  font-size: 1rem;
  color: #0879dc;
}

