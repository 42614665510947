@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");
.main {
  border: 1px solid red;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  height: 50vh;
  width: 50%;
  margin-left: 200px;
}
.Bitcoin {
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.fund {
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.Terms {
  background-color: red;
  height: 100px;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 10px;
  width: 60%;
  z-index: 1;
  margin-left: 315px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.701); /* semi-transparent black */
}

.heading {
  font-size: 100px;
  color: #04fefc;
  margin-top: 12px;
  font-family: "Lora", serif;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  overflow: auto;
  animation: animate 10s linear infinite;
  outline: none;
}
.about {
  color: #04fefc;
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: "Lora", serif;
}
.about:hover {
  text-shadow: -2px 4px 8px rgba(4, 254, 252, 0.82);
  cursor: pointer;
}

@keyframes animate {
  0%,
  18%,
  20%,
  2.1%,
  60%,
  65.1%,
  80%,
  90.1%,
  92% {
    color: #0e3742;
    text-shadow: none;
  }
  18.1%,
  20.1%,
  30%,
  50%,
  60.1%,
  65%,
  80.1%,
  90%,
  92.1%,
  100% {
    color: #0e3742;
    text-shadow: 0 0 10px #04fefc, 0 0 10px #04fefc, 0 0 10px #04fefc,
      0 0 10px #04fefc, 0 0 10px #04fefc;
  }
}
