@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");

* {
  padding: 0;
  margin: 0;
}

.logo {
  margin: auto;
}
.Navbar {
  height: 15vh;
  background-image: linear-gradient(to right, #e2eefb 50%, #f2f5f9);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.buttons {
  height: 6vh;
  width: 19vh;
  background-color: transparent;
  text-decoration: none;
  font-size: 20px;
  color: #2196f3;
  text-align: center;
  border: none;
  padding: 5px;
  font-family: "Roboto Slab", serif;
  cursor: pointer;
}
.buttons:hover {
  transition: 0.3s ease-in;
  color: #ffb711;
  letter-spacing: 2px;
  border: none;
  border-radius: 20px;
  font-weight: 500;
  -webkit-box-reflect: below 10px
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}
.loginButtons {
  background-image: linear-gradient(to bottom right, #f60000ea 30%, #ffb811);
  color: black;
  border-radius: 20px;
  font-weight: 900;
}
.log-out {
  border-radius: 50%;
}
.log-out:hover {
  box-shadow: 4px 7px 55px -6px rgba(57, 242, 242, 0.855);
  -webkit-box-shadow: 4px 7px 55px -6px rgba(57, 242, 242, 0.855);
  -moz-box-shadow: 4px 7px 55px -6px rgba(57, 242, 242, 0.855);
  cursor: pointer;
}



.buttons:active {
  transform: translateY(3px);
}

/*for login droupdown*/
.logoutAlert {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom right, #434344, #000000);
  padding: 2rem;
  z-index: 1;
  position: absolute;
  width: 30rem;
  border-radius: 1rem;
  font-family: "Lora", serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.7rem;
  top: 8.8rem;
  right: 10px;
  color: white;
}

.alert-btn-container {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.alert-btn {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  width: 5rem;
  font-weight: 900;
  font-family: "Lora", serif;
  box-shadow: 4px 7px 55px -6px rgba(200, 255, 255, 0.855);
  -webkit-box-shadow: 4px 7px 55px -6px rgba(200, 255, 255, 0.855);
  -moz-box-shadow: 4px 7px 55px -6px rgba(200, 255, 255, 0.855);
  cursor: pointer;
  color: whitesmoke;
  background-image: linear-gradient(to top right, #a40606, #a71d31);
}

.alert-btn.no {
  background-image: linear-gradient(to bottom right, #0bab64, #3bb78f);
  outline: none;
  color: #ffb711;
}

/*Responsiveness*/
@media screen and (max-width: 768px) {
  .Navbar {
    height: auto;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
  }
  .buttons {
    height: auto;
    width: auto;
    margin: 1rem 0;
  }
  .search-icon {
    display: none;
  }
  .logoutAlert {
    width: 90%;
    font-size: 1rem;
  }
  .alert-btn {
    padding: 0.5rem;
    font-size: 1rem;
    width: 50%;
  }
  .loginButtons{
    width: 18vw;
  }
  .buttons:active {
    transform: translateY(3px);
  }

}
